import React from "react"
import QuizMaster from "../../../components/layouts/quiz_master"
import Settings from "../../../components/quiz/settings"
import ToggleList from "../../../components/quiz/toggle-list"
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Toggle from "../../../components/quiz/toggle"
import Number from "../../../components/japanese/other/number.js"

const defaultDigits = 5;

class Numbers extends React.Component  {
  constructor(props) {
    super(props);
    this.number = "";
    this.lastnumber = "";
    this.answer = "";
    this.digits = defaultDigits;
    this.correct = 0;
    this.incorrect = 0;
    this.newNumber = this.newNumber.bind(this);
    this.checkAnswer = this.checkAnswer.bind(this);

    this.state = {
        loaded: false,
        number: this.number,
        lastnumber: this.number,
        question: "",
        lastAnswer: "",
        digits: this.digits,
        status: "",
        wait: false,
        correct: this.correct,
        incorrect: this.incorrect,
    };
  }

  newNumber() {
    var number;
    do {
      number = Math.floor(Math.random() * Math.pow(10, this.digits)).toString()
    } while (this.lastnumber === number) // Prevents same number from appearing twice in a row
    this.number = number;
    this.lastnumber = number;
    let status = "";
    this.setState({ number: number, lastnumber: number, wait: false, status: status});
    this.updateQuestion()
    this.updateAnswer()
  }

  updateQuestion() {
    this.setState({ question: this.number });
  }

  updateAnswer() {
    this.answer = Number.getAnswer(this.number);
    if(this.reverse) {
      this.updateReverse()
    }
  }

  updateReverse() {
    var tempnum = this.number;
    var newQuestion;
		if (tempnum < 10) {
			if (this.answer.length === 1) {newQuestion = this.answer[0];}
			else {newQuestion = this.answer[Math.floor(Math.random() * 2)];}
		}
		else {newQuestion = this.answer;}
		this.answer = tempnum;

    this.setState({ question: newQuestion });
  }

  checkAnswer(userAnswer) {
    var status = "";
    if(this.answer === userAnswer || (Array.isArray(this.answer) && this.answer.includes(userAnswer))) {
      status = "correct";
      this.correct += 1;
    } else {
      status = "incorrect";
      this.incorrect += 1;
    }
    let lastAnswer = this.answer;
    this.setState({status: status, correct: this.correct, incorrect: this.incorrect, lastAnswer: lastAnswer, wait: true})
  }

  digitsChange = (e, num) => {
    this.digits = num;
    this.newNumber();
  }

  reverseToggle = e => {
    this.reverse = !this.reverse;
    this.setState({ reverse: this.reverse });
    this.newNumber();
  }

  componentDidMount() {
    if(!this.state.loaded) {
      this.newNumber()
      this.setState({ loaded: true });
    }
  }

  render() {
    return (
      <QuizMaster
        title="Japanese Numbers"
        description="Interactive Study tool for learning Japanese numbers. Study and learn the numbers from 1 to 8 digits in length."
        question={this.state.question}
        wait={this.state.wait}
        status={this.state.status}
        onSubmit={this.checkAnswer}
        onClear={this.newNumber}
        sentence={!this.state.reverse}
        noCheck={this.state.reverse}
        correct={this.state.correct}
        incorrect={this.state.incorrect}
        answer={this.state.lastAnswer}
        settings={
          <Settings>
            <ToggleList>
              <Typography style={{color:"#777"}}>Digits</Typography>
              <Slider min={1} max={8} marks={true} defaultValue={defaultDigits} valueLabelDisplay="auto" onChangeCommitted={this.digitsChange} style={{color:"#098fe6"}} />
              <Toggle id="reversesetting" label="Hiragana⇄Number" checked={this.state.reverse} onToggle={this.reverseToggle} />
            </ToggleList>
          </Settings>
        }
      >
      </QuizMaster>
    );
  }
}

export default Numbers
